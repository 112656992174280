import { z } from 'zod';
const floatingPointPrecisionSchema = z.number().refine(val => {
    return val >= 0 && Number.isFinite(val) && Math.abs(val - parseFloat(val.toFixed(3))) < 1e-10;
}, {
    message: 'Floating point numbers must be non-negative and have at most three decimal places!',
});
export const GetPositionQuoteResponseSchema = z.strictObject({
    positionId: z.string().uuid(),
    positionProductId: z.string().uuid(),
    name: z.string(),
    status: z.enum(['DRAFT', 'SUBMITTED', 'CANCELED']),
    quota: floatingPointPrecisionSchema,
    fulfilledQuantity: floatingPointPrecisionSchema,
    reservedQuantity: floatingPointPrecisionSchema,
    unit: z.enum([
        'DAY',
        'HOUR',
        'KILOMETER',
        'KILOGRAM',
        'TON',
        'CUBIC_METER',
        'CUBIC_CENTIMETER',
        'LITER',
        'PIECE',
        'TRANSPORT',
        'FLAT_RATE',
        'MINUTE',
    ]),
    partnerOrganizationId: z.number().positive(),
    projectOwnerOrganizationId: z.number().positive(),
    platformOrganizationId: z.number().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: z.string().max(3),
    productCategory: z.enum(['MATERIAL', 'TRANSPORT', 'MATERIAL_TRANSPORT', 'MATERIAL_SERVICE', 'TRANSPORT_SERVICE']),
    productType: z.enum([
        'CUSTOM_BULK_MATERIAL',
        'CUSTOM_RECYCLED_BULK_MATERIAL',
        'CUSTOM_TRANSPORT',
        'CUSTOM_CONCRETE',
        'CUSTOM_ASPHALT',
        'CUSTOM_SERVICE',
        'CUSTOM_SERVICE_ANALYSIS',
        'CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT',
        'CUSTOM_SERVICE_TOLL',
        'CUSTOM_SERVICE_WAITING_TIME',
        'CUSTOM_SERVICE_TRANSPORT',
        'BULK_MATERIAL',
        'TRANSPORT',
        'CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING',
        'CUSTOM_HAZARDOUS_WASTE_RECYCLING',
        'CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL',
        'CUSTOM_HAZARDOUS_WASTE_DISPOSAL',
    ]),
    updatedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
});
