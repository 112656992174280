import { GenericPositionProduct } from '@/modules/constructionProjects/components/GenericPositionProducts/GenericPositionProduct';
import { useState } from 'react';
import { PlusIcon } from '@schuettflix/planum-icons-react';
import { useTranslation } from 'react-i18next';
export function GenericPositionProducts({ products, numberToShow, productGroupType }) {
    const { t } = useTranslation();
    const currentPositionProducts = productGroupType === 'FRANCO' ? products.filter(product => product.category !== 'TRANSPORT') : products;
    const defaultProducts = currentPositionProducts.slice(0, numberToShow);
    const remainingProducts = currentPositionProducts.slice(numberToShow, currentPositionProducts.length);
    const hasRemainingProducts = remainingProducts.length !== 0;
    const [showProducts, setShowProducts] = useState(false);
    const handleShowProducts = e => {
        e.stopPropagation();
        setShowProducts(true);
    };
    return (<div className="flex w-full flex-col gap-6">
            {defaultProducts.map(product => (<GenericPositionProduct key={product.id} positionProduct={product}/>))}

            {hasRemainingProducts && (<>
                    {showProducts ? (remainingProducts.map(product => (<GenericPositionProduct key={product.id} positionProduct={product}/>))) : (<div className="font-copy-md mt-2 flex items-center gap-2" onClick={handleShowProducts}>
                            <PlusIcon size={16}/>
                            <span className="whitespace-nowrap">
                                {t('components.genericPositionProducts.showMore', {
                    total: remainingProducts.length,
                })}
                            </span>
                        </div>)}
                </>)}
        </div>);
}
