import { constructionProjectByIdAsAdminQuery } from '@/constructionProjects/queries';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from '@/reactBridge/useRoute';
import { useConstructionProjectValidity } from '@/modules/constructionProjects/hooks/useConstructionProjectValidity';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { useGenericPositionById } from '../hooks/useGenericPositionById';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { Link, LoadingSpinner, Tag } from '@schuettflix/react-components';
import { OrgInfo } from './OrgInfo';
import { useCreateGenericOrderByPositionAndForward } from '@/modules/constructionProjects/hooks/useCreateGenericOrderByGenericPositionAndForward';
import { MinusIcon, PlusIcon } from '@schuettflix/icons-react';
import { GenericPositionActions } from './GenericPositionActions';
import { PositionOverviewContent } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/PositionOverviewContent';
import { useActivateGenericPositionById } from '../hooks/useActivateGenericPositionById';
import { asyncDelay } from '@/services/utils';
import { invalidateGetGenericPositionByIdQuery } from '@/modules/constructionProjects/api/admin/getGenericPositionById';
import Toaster from '@/services/Toaster';
import { GenericPositionProducts } from '@/modules/constructionProjects/components/GenericPositionProducts/GenericPositionProducts';
function DetailsSection({ position }) {
    const { t } = useTranslation();
    const [showFullNote, setShowFullNote] = React.useState(false);
    const [openInactivePositionModal, setOpenInactivePositionModal] = useState(false);
    const [openModalPricesSummary, setOpenModalPricesSummary] = useState(false);
    const activateGenericPosition = useActivateGenericPositionById(position.id);
    const queryClient = useQueryClient();
    const productGroup = position.positionProductGroups[0];
    const { validityRange, daysLeft, showWarning } = useConstructionProjectValidity({
        validFrom: position.validFrom ?? '',
        validTo: position.validTo ?? '',
    });
    const createGenericOrder = useCreateGenericOrderByPositionAndForward(position.id);
    // Confirm closing shows a loading indicator optimistically (2 secs or so) for monolith-service-sync reasons
    const [fakeLoading, setFakeLoading] = useState(false);
    if (!productGroup) {
        return null;
    }
    const positionNote = position.note?.length
        ? {
            fullNote: position.note,
            shortNote: position.note.length > 220 ? `${position.note.slice(0, 220)}...` : position.note,
            hasMore: position.note.length > 220,
        }
        : undefined;
    return (<section className="space-y-6">
            <div className="card rounded p-4 shadow-flat md:p-6">
                <div className="mb-6 flex flex-col items-start justify-between gap-2 md:flex-row md:gap-0">
                    <div className="flex flex-col">
                        <p className="font-copy-sm text-subdued">{t('pages.genericPosition.details.validityField')}</p>
                        <div className="flex flex-row-reverse gap-4 md:flex-row">
                            <p className="font-headline-lg text">{validityRange}</p>
                            {showWarning && (<Tag type="warning" label={t('pages.genericPosition.details.expirationWarning', {
                count: daysLeft,
            })}/>)}
                        </div>
                    </div>
                </div>

                <div className="lg:w-1/2">
                    <GenericPositionProducts products={productGroup.products} productGroupType={productGroup.type} numberToShow={3}/>
                </div>

                <div className="mt-8 flex max-w-screen-lg flex-col gap-2">
                    <Link label={t('pages.genericPosition.prices-and-services')} className="font-copy-md-strong" onClick={() => setOpenModalPricesSummary(true)}/>

                    <ModalTrigger isOpen={openModalPricesSummary} onOpenChange={() => setOpenModalPricesSummary(!openModalPricesSummary)}>
                        <Modal className="max-h-18" isDismissable>
                            {({ close }) => (<>
                                    <Modal.Heading onAbort={close}>
                                        {t('pages.genericPosition.prices-and-services')}
                                    </Modal.Heading>
                                    <Modal.Content>
                                        <Suspense fallback={<div className="flex justify-center p-8">
                                                    <LoadingSpinner block/>
                                                </div>}>
                                            <PositionOverviewContent positionId={position.id} marketCode={position.marketCode}/>
                                        </Suspense>
                                    </Modal.Content>
                                </>)}
                        </Modal>
                    </ModalTrigger>
                </div>

                {positionNote && (<div className="mt-8 flex max-w-screen-lg flex-col gap-2">
                        <h4 className="font-copy-md-strong">{t('pages.genericPosition.details.note')}</h4>
                        <p className="font-copy-md">{showFullNote ? positionNote.fullNote : positionNote.shortNote}</p>
                        {positionNote?.hasMore && (<Link className="font-bold" prefix={showFullNote ? <MinusIcon /> : <PlusIcon />} label={showFullNote
                    ? t('pages.genericPosition.details.showLess')
                    : t('pages.genericPosition.details.showMore')} onClick={() => setShowFullNote(!showFullNote)}/>)}
                    </div>)}
            </div>
            {position.status === 'OPEN' && (<div className="flex justify-end gap-6">
                    <Button prominence="primary" stature="md" className="rounded" leadingSlot={createGenericOrder.isLoading ? <LoadingSpinner /> : undefined} onPress={() => {
                if (position.active) {
                    return createGenericOrder.mutateAsync();
                }
                setOpenInactivePositionModal(true);
            }} isDisabled={createGenericOrder.isLoading}>
                        {t('pages.genericPosition.details.startOrder')}
                    </Button>
                    <ModalTrigger isOpen={openInactivePositionModal} onOpenChange={() => setOpenInactivePositionModal(!openInactivePositionModal)}>
                        <Modal>
                            {({ close }) => (<>
                                    <Modal.Heading onAbort={close}>
                                        {t('pages.genericPosition.details.orderingOnInactiveGenericPositionModal.title')}
                                    </Modal.Heading>
                                    <Modal.Content>
                                        {t('pages.genericPosition.details.orderingOnInactiveGenericPositionModal.content')}
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onPress={close} prominence="secondary">
                                            {t('pages.genericPosition.details.orderingOnInactiveGenericPositionModal.cancel')}
                                        </Button>
                                        <Button prominence="primary" leadingSlot={(activateGenericPosition.isLoading || fakeLoading) && <LoadingSpinner />} isDisabled={activateGenericPosition.isLoading || fakeLoading} onPress={async () => {
                    setFakeLoading(true);
                    try {
                        await Promise.all([
                            activateGenericPosition.mutateAsync(),
                            await asyncDelay(1000),
                        ]);
                        invalidateGetGenericPositionByIdQuery(queryClient, position.id);
                        close();
                        Toaster.success(t('pages.genericPosition.details.activateGenericPosition.success'));
                    }
                    catch (error) {
                        close();
                        Toaster.error(t('pages.genericPosition.details.activateGenericPosition.error'));
                    }
                    finally {
                        setFakeLoading(false);
                    }
                }}>
                                            {t('pages.genericPosition.details.contextMenu.activateGenericPosition')}
                                        </Button>
                                    </Modal.Actions>
                                </>)}
                        </Modal>
                    </ModalTrigger>
                </div>)}
        </section>);
}
export const GenericPositionDetails = ({ className }) => {
    const { t } = useTranslation();
    const { projectUuid } = useConstructionProjectRouteParams();
    const currentRoute = useRoute();
    const { data: constructionProject } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectUuid),
        suspense: true,
    });
    const { data: position } = useGenericPositionById(currentRoute.params.positionId);
    if (!constructionProject || !position) {
        return null;
    }
    return (<div className={clsx('flex flex-grow flex-col gap-12', className)}>
            <div className="flex flex-col-reverse justify-between gap-2 md:flex-row md:items-center md:gap-12">
                <OrgInfo />
                <div className="flex items-center gap-2">
                    <Tag className="self-end md:self-auto" type={position.status === 'CLOSED' ? 'error' : position.active ? 'positive' : 'default'} label={t(`pages.genericPosition.details.tags.${position.status === 'CLOSED' ? 'CLOSED' : position.active ? 'ACTIVE' : 'INACTIVE'}`)}/>
                    <div className="hidden lg:flex">
                        <GenericPositionActions genericPositionId={position.id}/>
                    </div>
                </div>
            </div>
            <DetailsSection constructionProject={constructionProject} position={position}/>
        </div>);
};
