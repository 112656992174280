import * as z from 'zod';
const ProjectNumberSchema = z.string().regex(/^PJ?\d{2}-\d{5}$/, {
    message: 'Project number must follow the format PJXX-XXXXX where X are digits',
});
const ProjectPositionNumberSchema = z.string().regex(/^PJ?\d{2}-\d{5}-5\d{2}$/, {
    message: 'Project Position Number must follow the format PJ24-00100-5XX where XX are digits',
});
const POSITION_STATUS_SCHEMA = z.enum(['DRAFT', 'OPEN', 'CLOSED']);
const PRODUCT_TYPE_SCHEMA = z.enum([
    'CUSTOM_BULK_MATERIAL',
    'CUSTOM_RECYCLED_BULK_MATERIAL',
    'CUSTOM_TRANSPORT',
    'CUSTOM_CONCRETE',
    'CUSTOM_ASPHALT',
    'CUSTOM_SERVICE',
    'CUSTOM_SERVICE_ANALYSIS',
    'CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT',
    'CUSTOM_SERVICE_TOLL',
    'CUSTOM_SERVICE_WAITING_TIME',
    'CUSTOM_SERVICE_TRANSPORT',
    'BULK_MATERIAL',
    'TRANSPORT',
    'CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING',
    'CUSTOM_HAZARDOUS_WASTE_RECYCLING',
    'CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL',
    'CUSTOM_HAZARDOUS_WASTE_DISPOSAL',
]);
export const PRODUCT_CATEGORY_SCHEMA = z.enum([
    'MATERIAL',
    'TRANSPORT',
    'MATERIAL_TRANSPORT',
    'MATERIAL_SERVICE',
    'TRANSPORT_SERVICE',
]);
export const PRODUCT_GROUP_TYPE_SCHEMA = z.enum(['FRANCO', 'MERCHANT', 'STANDARD']);
const CustomerInfoSchema = z.strictObject({
    id: z.string().uuid(),
    positionId: z.string().uuid(),
    constructionProjectId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});
export const PositionProductSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    unit: z.string(),
    quota: z.number(),
    availableQuantity: z.number(),
    reservedQuantity: z.number().nonnegative(),
    fulfilledQuantity: z.number().nonnegative(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    name: z.string(),
});
const PositionProductGroupSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    positionId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    products: z.array(PositionProductSchema),
});
export const GetPositionResponseSchema = z.strictObject({
    id: z.string().uuid(),
    projectNumber: ProjectNumberSchema,
    positionNumber: ProjectPositionNumberSchema,
    marketCode: z.string(),
    validFrom: z.coerce.date().nullable(),
    validTo: z.coerce.date().nullable(),
    note: z.string().nullable(),
    status: POSITION_STATUS_SCHEMA,
    active: z.boolean(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
    orderCount: z.number(),
    customerInfo: CustomerInfoSchema,
    positionProductGroups: z.array(PositionProductGroupSchema),
});
export const ListGenericPositionItemSchema = z.object({
    ...GetPositionResponseSchema.shape,
});
export const ListGenericPositionsResponseSchema = z.object({
    positions: z.array(ListGenericPositionItemSchema),
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
});
export const GetPositionProductGroupProductsSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    category: z.enum(['MATERIAL', 'TRANSPORT', 'MATERIAL_TRANSPORT', 'MATERIAL_SERVICE', 'TRANSPORT_SERVICE']),
    type: z.enum([
        'CUSTOM_BULK_MATERIAL',
        'CUSTOM_RECYCLED_BULK_MATERIAL',
        'CUSTOM_TRANSPORT',
        'CUSTOM_CONCRETE',
        'CUSTOM_ASPHALT',
        'CUSTOM_SERVICE',
        'CUSTOM_SERVICE_ANALYSIS',
        'CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT',
        'CUSTOM_SERVICE_TOLL',
        'CUSTOM_SERVICE_WAITING_TIME',
        'CUSTOM_SERVICE_TRANSPORT',
        'BULK_MATERIAL',
        'TRANSPORT',
        'CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING',
        'CUSTOM_HAZARDOUS_WASTE_RECYCLING',
        'CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL',
        'CUSTOM_HAZARDOUS_WASTE_DISPOSAL',
    ]),
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
});
export const GetPositionProductGroupUrlSchema = z.object({
    positionId: z.string().uuid(),
});
export const GetPositionProductGroupsResponseSchema = z.array(z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: z.enum(['FRANCO', 'MERCHANT', 'STANDARD']),
    positionId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    products: z.array(GetPositionProductGroupProductsSchema).optional(),
}));
export const ORDER_ORGANIZATION_ROLE_SCHEMA = z.enum(['CUSTOMER', 'SUPPLIER', 'CARRIER', 'CUSTOM']);
export const TOTAL_TYPE_SCHEMA = z.enum(['ORDER', 'FULFILLMENT']);
export const ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA = z.enum(['DEBTOR', 'CREDITOR']);
export const TotalSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    totalPriceAmount: z.number(),
    totalPriceCurrency: z.string(),
});
export const TransactionalTotalsSchema = z.strictObject({
    totalType: TOTAL_TYPE_SCHEMA,
    transactionalTotals: z.array(TotalSchema),
});
export const TransactionSchema = z.object({
    productId: z.string().uuid(),
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
    quantity: z.number(),
    unit: z.string(),
    unitTaxClassId: z.string().optional(),
    unitPriceAmount: z.number(),
    unitPriceCurrency: z.string().length(3),
    productName: z.string(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
});
export const BILLING_TYPE_SCHEMA = z.enum(['INVOICE', 'PARTNER_INVOICE', 'CREDIT_NOTE']);
export const PaymentSettingSchema = z.strictObject({
    billingMethod: BILLING_TYPE_SCHEMA,
    paymentTermId: z.number().nullable(),
});
export const PaymentSettingsWithTransactionalRoleResponseSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    ...PaymentSettingSchema.shape,
});
export const PositionOverviewOrganizationSchema = z.strictObject({
    orgId: z.number(),
    functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
    financeSettings: z.array(PaymentSettingsWithTransactionalRoleResponseSchema),
    totals: z.array(TransactionalTotalsSchema),
    transactions: z.array(TransactionSchema),
});
export const GetPositionOverviewResponseSchema = z.strictObject({
    organizations: z.array(PositionOverviewOrganizationSchema),
});
